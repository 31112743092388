import Home from '@/router/views/Home.vue';

export default {
  path: '/elementary',
  name: 'elementary',
  component: Home,

  children: [
    {
      path: 'community',
      component: () => import('@components/information/elementary/Community.vue'),
    },
    {
      path: 'community/view/:id',
      component: () => import('@components/information/elementary/CommunityView.vue'),
    },
    {
      path: 'community/write',
      component: () => import('@components/information/elementary/CommunityWrite.vue'),
    },
    {
      path: 'community/write/:id',
      component: () => import('@components/information/elementary/CommunityWrite.vue'),
    },

    {
      path: 'choice-book',
      component: () => import('@components/information/elementary/ChoiceBook.vue'),
    },
    {
      path: 'choice-book/view/:id',
      component: () => import('@components/information/elementary/ChoiceBookView.vue'),
    },
    {
      path: 'choice-book/write',
      component: () => import('@components/information/elementary/ChoiceBookWrite.vue'),
    },
    {
      path: 'choice-book/write/:id',
      component: () => import('@components/information/elementary/ChoiceBookWrite.vue'),
    },

    {
      path: 'recommend-book',
      component: () => import('@components/information/elementary/RecommendBook.vue'),
    },
    {
      path: 'recommend-book/view/:id',
      component: () => import('@components/information/elementary/RecommendBookView.vue'),
    },
    {
      path: 'recommend-book/write',
      component: () => import('@components/information/elementary/RecommendBookWrite.vue'),
    },
    {
      path: 'recommend-book/write/:id',
      component: () => import('@components/information/elementary/RecommendBookWrite.vue'),
    },
    {
      path: 'program-guide',
      component: () => import('@components/information/elementary/ProgramGuide.vue'),
    },
    {
      path: 'curriculum',
      component: () => import('@components/information/elementary/Curriculum.vue'),
    },
  ],
};
