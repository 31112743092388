import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/plugins/axios.js';
import axiosReq from '@/plugins/axios-req.js';
import title from '@/mixin/title.js';
import VueCookies from 'vue-cookies';

import './design/tailwind.css';

const app = createApp(App);

app.config.globalProperties.axios = axios;
app.config.globalProperties.axiosReq = axiosReq;

app.use(store);
app.use(router);
app.use(VueCookies);

app.mixin(title);

app.mount('#app');
