const suffix = 'C&A';

export default {
  data() {
    return { pageTitle: '' };
  },
  created() {
    this.changeTitle('');
  },
  watch: {
    pageTitle: function () {
      this.changeTitle(this.pageTitle);
    },
  },
  methods: {
    changeTitle(to) {
      document.title = to ? `${to} < ${suffix}` : suffix;
    },
  },
};
