<template>
  <footer>
    <div class="footerWrap">
      <ul class="w-full">
        <li>
          <router-link to="/privacy">개인정보보호정책</router-link>
        </li>
        <li>
          <router-link to="/service">이용약관</router-link>
        </li>
        <li>
          <router-link to="/about-map">찾아오시는길</router-link>
        </li>
      </ul>
      <h2><img src="@assets/images/logo_footer.jpg" alt="씨앤에이논술"/></h2>
      <address>
        <span>대표이사 : 이재종</span>
        <span>사업자등록번호 : 120-87-55630</span><br/>
        <span>주소 : 서울특별시 강남구 도곡로 450 (대치동, 덕산빌딩2층) (주)씨앤에이논술</span>
        <span>TEL : 02-562-1211 / FAX : 02-562-6566</span> <br/>
        <span>개인정보관리책임자 : 여성오</span> <span>이메일 : 92light@daum.net</span><br/><br/>
        <span>COPYRIGHT &copy; 씨앤에이논술 INC. ALL RIGHTS RESERVED.</span>
      </address>
    </div>
  </footer>
</template>

<script></script>

<style scoped>
footer {
  background: #221815;
  height: 190px;
  width: 1280px;
  margin: 0 auto;
}

.footerWrap {
  width: 1280px;
  margin: 0 auto;
  font-size: 0.8rem;
}

ul {
  height: 45px;
  border-bottom: 1px solid #888;
  padding: 10px 0 10px 0;
}

ul li {
  display: inline-block;
  padding: 0 25px;
  border-right: 1px solid #888;
  line-height: 16px;
}

ul li:last-child {
  border-right: none;
}

ul li a {
  line-height: 16px;
  color: #aaa;
}

h2,
address {
  display: inline-block;
  height: 143px;
  vertical-align: middle;
}

h2 {
  padding: 43px 0 0 26px;
  width: 230px;
}

address {
  padding-top: 20px;
}

address span {
  color: #aaa;
  padding-right: 20px;
}
</style>
