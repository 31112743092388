<template>
  <div>
    <header>
      <div class="headWrap">
        <h1>
          <router-link to="/">
            <img src="@assets/images/logo.jpg " alt="씨앤에이논술"/>
          </router-link>
        </h1>
        <ul id="topnav">
          <li>
            <router-link to="/about">학원소개</router-link>
            <span>
              <router-link to="/about"><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A"/>논술 소개</router-link>
              <router-link to="/about-map"><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A"/>논술 배움터</router-link>
              <router-link to="/about-tuition"
              ><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A"/>논술 수강료</router-link
              >
              <router-link to="/about-book"
              ><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A"/>논술 교재소개</router-link
              >
              <!--router-link to="AboutBk"><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A" />논술 출간도서</!--router-link-->
              <router-link to="/about-store"
              ><img src="@assets/images/m_cna.png" class="m" alt="C&amp;A"/>논술 가맹문의</router-link
              >
            </span>
          </li>
          <li>
            <router-link to="/elementary/community">초등부</router-link>
            <span>
              <router-link to="/elementary/community">커뮤니티</router-link>
              <router-link to="/elementary/choice-book">선정도서</router-link>
              <router-link to="/elementary/curriculum">커리큘럼</router-link>
              <router-link to="/elementary/program-guide">프로그램 안내</router-link>
              <!--<router-link to="/elementary/recommend-book">추천 도서</router-link>-->
            </span>
          </li>
          <li>
            <router-link to="/middle/community">중등부</router-link>
            <span>
              <router-link to="/middle/community">커뮤니티</router-link>
              <router-link to="/middle/choice-book">선정도서</router-link>
              <router-link to="/middle/curriculum">커리큘럼</router-link>
              <router-link to="/middle/program-guide">프로그램 안내</router-link>
              <!--<router-link to="/middle/recommend-book">추천 도서</router-link>-->
            </span>
          </li>
          <li>
            <router-link to="/high/community">고등부</router-link>
            <span>
              <router-link to="/high/community">커뮤니티</router-link>
              <router-link to="/high/choice-book">선정도서</router-link>
              <router-link to="/high/curriculum">커리큘럼</router-link>
              <router-link to="/high/program-guide">프로그램 안내</router-link>
              <!--<router-link to="/high/recommend-book">추천 도서</router-link>-->
            </span>
          </li>
          <li>
            <router-link to="/cna-news/notice"><img src="@assets/images/m_cna2.png" class="m2" alt="C&amp;A"/>소식</router-link>
            <span>
              <router-link to="/cna-news/notice">공지사항</router-link>
              <router-link to="/cna-news/customer-qna">고객문의</router-link>
              <router-link to="/cna-news/video-room">영상실</router-link>
              <router-link to="/cna-news/gallery">갤러리</router-link>
            </span>
          </li>
        </ul>
        <div class="headSide">
          <ul class="topMside01">
            <li>
              <keep-alive v-if="!$store.getters['userStore/isLogin'] && keepCache" :max="maxCache">
                <router-link to="/login">로그인</router-link>
              </keep-alive>
              <keep-alive v-if="$store.getters['userStore/isLogin'] && keepCache" :max="maxCache">
                <a @click="logout">로그아웃</a>
              </keep-alive>
            </li>
            <!--li><router-link to="#">회원가입</router-link></!--li-->
            <li>
              <router-link to="/about-store">가맹문의</router-link>
            </li>
            <li>
              <router-link to="/about-map">전국지점</router-link>
            </li>
          </ul>
          <ul class="topMside02">
            <li>
              <a @click="this.popupLink('https://www.saramin.co.kr/zf_user/jobs/relay/view?rec_idx=47999442&view_type=search')">채용문의</a>
            </li>
            <li>
              <a @click="this.popupLink('https://cnanonsul.com')">INTRANET</a>
            </li>
            <li>
              <a @click="this.popupLink('https://blog.naver.com/cnaedu')">블로그</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    navs: Array,
  },
  data() {
    return {keepCache: true, maxCache: 20};
  },
  mounted() {
  },
  methods: {
    popupLink(url) {
      window.open(url);
    },
    logout() {
      if (!confirm('로그아웃 하시겠습니까?')) {
        return;
      }
      this.$store.commit('userStore/logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
header {
  background: #690000;
  height: 90px;
  width: 1280px;
  margin: 0 auto;
}

.headWrap {
  width: 100%;
  height: 90px;
  margin: 0 auto;
}

header h1 {
  display: inline-block;
  width: 253px;
  vertical-align: middle;
  padding-left: 20px;
}

ul#topnav {
  list-style: none;
  position: relative;
  width: 480px;
  height: 90px;
  vertical-align: middle;
  display: inline-block;
  color: #fff;
}

ul#topnav li {
  float: left;
  margin: 0;
  padding-top: 10px;
}

ul#topnav li a {
  padding: 0 12px;
  line-height: 80px;
  display: block;
  color: #333;
  font-weight: 500;
  font-size: 1.3rem;
  color: #fff;
}

ul#topnav li span {
  float: left;
  padding: 0 0;
  position: absolute;
  left: 0;
  top: 80px;
  display: none;
  width: 720px;
  background: #dc6400;
  color: #fff;
  text-align: left;
  padding-left: 10px;
}

ul#topnav li:nth-child(2) span {
  padding-left: 60px;
}

ul#topnav li:nth-child(3) span {
  padding-left: 100px;
}

ul#topnav li:nth-child(4) span {
  padding-left: 120px;
}

ul#topnav li:nth-child(5) span {
  padding-left: 250px;
}

ul#topnav li:hover span {
  display: block;
}

ul#topnav li span a {
  display: inline-block;
  font-size: 0.85rem;
  font-weight: normal;
  color: #fff;
  padding: 0 15px;
  line-height: 40px;
}

ul#topnav li span a:hover {
  background: none;
  color: #fff;
  text-decoration: underline;
}

ul#topnav li span a img.m {
  display: inline;
  vertical-align: middle;
  border-bottom: 1px solid #dc6400;
}

ul#topnav li span a:hover img.m {
  border-bottom: 1px solid #fff;
}

.m2 {
  display: inline;
}

.headSide {
  display: inline-block;
  width: 540px;
  vertical-align: middle;
  text-align: right;
}

.topMside01 {
  display: inline-block;
  width: 230px;
  padding-left: 0px;
}

.topMside01 li {
  display: inline-block;
  line-height: 50px;
}

.topMside01 li a {
  font-size: 0.95rem;
  color: #fff;
  display: inline-block;
  padding: 30px 10px 0 10px;
}

.topMside01 li:first-child a {
  background: url(@assets/images/topSide_01.jpg) 50% 15px no-repeat;
}

.topMside01 li:nth-child(2) a {
  background: url(@assets/images/topSide_02.jpg) 50% 15px no-repeat;
}

.topMside01 li:nth-child(3) a {
  background: url(@assets/images/topSide_03.jpg) 50% 15px no-repeat;
}

.topMside01 li:nth-child(4) a {
  background: url(@assets/images/topSide_04.jpg) 50% 15px no-repeat;
}

.topMside02 {
  display: inline-block;
  width: 282px;
  padding-left: 6px;
  margin-right: 20px;
  vertical-align: text-bottom;
}

.topMside02 li {
  display: inline-block;
  line-height: 50px;
}

.topMside02 li a {
  font-size: 0.84rem;
  letter-spacing: -0.05rem;
  display: inline-block;
  padding: 0;
  border-radius: 25px;
  background: #fff;
  line-height: 30px;
  width: 82px;
  text-align: center;
  margin-left: 10px;
  color: #2a4e79;
  font-weight: 500;
}

.topMside02 li:last-child a {
  background: #fed100;
}

.topMside02 img {
  display: inline;
}
</style>
