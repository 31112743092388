import Home from '@/router/views/Home.vue';

export default {
  path: '/high',
  name: 'high',
  component: Home,

  children: [
    {
      path: 'community',
      component: () => import('@components/information/high/Community.vue'),
    },
    {
      path: 'community/view/:id',
      component: () => import('@components/information/high/CommunityView.vue'),
    },
    {
      path: 'community/write',
      component: () => import('@components/information/high/CommunityWrite.vue'),
    },
    {
      path: 'community/write/:id',
      component: () => import('@components/information/high/CommunityWrite.vue'),
    },

    {
      path: 'choice-book',
      component: () => import('@components/information/high/ChoiceBook.vue'),
    },
    {
      path: 'choice-book/view/:id',
      component: () => import('@components/information/high/ChoiceBookView.vue'),
    },
    {
      path: 'choice-book/write',
      component: () => import('@components/information/high/ChoiceBookWrite.vue'),
    },
    {
      path: 'choice-book/write/:id',
      component: () => import('@components/information/high/ChoiceBookWrite.vue'),
    },

    {
      path: 'recommend-book',
      component: () => import('@components/information/high/RecommendBook.vue'),
    },
    {
      path: 'recommend-book/view/:id',
      component: () => import('@components/information/high/RecommendBookView.vue'),
    },
    {
      path: 'recommend-book/write',
      component: () => import('@components/information/high/RecommendBookWrite.vue'),
    },
    {
      path: 'recommend-book/write/:id',
      component: () => import('@components/information/high/RecommendBookWrite.vue'),
    },
    {
      path: 'program-guide',
      component: () => import('@components/information/high/ProgramGuide.vue'),
    },
    {
      path: 'curriculum',
      component: () => import('@components/information/high/Curriculum.vue'),
    },
  ],
};
