import { createStore } from 'vuex';
import userStore from '@/store/modules/userStore.js';
import ca from '@/store/modules/userStore.js';
import createPersistedState from 'vuex-persistedstate';
import constants from '@/store/modules/constants';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    userStore: userStore,
    constants: constants,
  },
  plugins: [createPersistedState({ paths: ['userStore'] })],
});
