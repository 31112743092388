import Home from '@/router/views/Home.vue';

export default {
  path: '/middle',
  name: 'middle',
  component: Home,

  children: [
    {
      path: 'community',
      component: () => import('@components/information/middle/Community.vue'),
    },
    {
      path: 'community/view/:id',
      component: () => import('@components/information/middle/CommunityView.vue'),
    },
    {
      path: 'community/write',
      component: () => import('@components/information/middle/CommunityWrite.vue'),
    },
    {
      path: 'community/write/:id',
      component: () => import('@components/information/middle/CommunityWrite.vue'),
    },

    {
      path: 'choice-book',
      component: () => import('@components/information/middle/ChoiceBook.vue'),
    },
    {
      path: 'choice-book/view/:id',
      component: () => import('@components/information/middle/ChoiceBookView.vue'),
    },
    {
      path: 'choice-book/write',
      component: () => import('@components/information/middle/ChoiceBookWrite.vue'),
    },
    {
      path: 'choice-book/write/:id',
      component: () => import('@components/information/middle/ChoiceBookWrite.vue'),
    },

    {
      path: 'recommend-book',
      component: () => import('@components/information/middle/RecommendBook.vue'),
    },
    {
      path: 'recommend-book/view/:id',
      component: () => import('@components/information/middle/RecommendBookView.vue'),
    },
    {
      path: 'recommend-book/write',
      component: () => import('@components/information/middle/RecommendBookWrite.vue'),
    },
    {
      path: 'recommend-book/write/:id',
      component: () => import('@components/information/middle/RecommendBookWrite.vue'),
    },
    {
      path: 'program-guide',
      component: () => import('@components/information/middle/ProgramGuide.vue'),
    },
    {
      path: 'curriculum',
      component: () => import('@components/information/middle/Curriculum.vue'),
    },
  ],
};
