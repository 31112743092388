import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import highRouter from '@/router/high-router';
import middleRouter from '@/router/middle-router';
import elementaryRouter from '@/router/elementary-router';
import cnaNewsRouter from '@/router/cnaNews-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/router/views/Home.vue'),
    children: [
      {
        path: '/',
        component: () => import('@components/Main.vue'),
      },
      {
        path: 'about',
        component: () => import('@components/About.vue'),
      },
      {
        path: 'about-map',
        component: () => import('@components/AboutMap.vue'),
      },
      {
        path: 'about-book',
        component: () => import('@components/AboutBook.vue'),
      },
      {
        path: 'about-store',
        component: () => import('@components/AboutStore.vue'),
      },
      {
        path: 'about-tuition',
        component: () => import('@components/AboutTuition.vue'),
      },
      {
        path: 'login',
        component: () => import('@components/login.vue'),
      },
      {
        path: 'privacy',
        component: () => import('@components/privacy.vue'),
      },
      {
        path: 'service',
        component: () => import('@components/service.vue'),
      },
      {
        path: '/:pathMatch(.*)',
        component: () => import('@components/Main.vue'),
      },
    ],
  },
  highRouter,
  middleRouter,
  elementaryRouter,
  cnaNewsRouter,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        el: '#app',
        behavior: 'smooth',
      };
    }
  },
});

export default router;
