<template>
  <div>
    <Nav></Nav>
    <div>
      <router-view></router-view>
    </div>
    <Copy></Copy>
  </div>
</template>

<script>
import Nav from '@components/Nav.vue';
import Copy from '@components/Copy.vue';

export default {
  name: 'Home',
  components: {
    Nav,
    Copy,
  },
};
</script>

<style></style>
