<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>
@import 'https://fonts.googleapis.com/earlyaccess/notosanskr.css';
@import '@assets/css/comm.css';
</style>
