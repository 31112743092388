// import jwt from 'jsonwebtoken';
import tokenUtil from '@utils/tokenUtil.js';
import * as jose from 'jose';

const userStore = {
  namespaced: true,
  state: {
    accessToken: '',
    refreshToken: '',
    email: '',
    phone: '',
    name: '',
    branchType: '',
    royalty: '',
    userGroup: '',
    userIdx: '',
    // adminId: '',
    // adminDept: '',
    // adminName: '',
    // adminScrGrpCd: '',
    // roleDetail: [],
    navigator: [],
  },
  mutations: {
    updateNavigator: function (state, payload) {
      state.navigator = payload;
    },
    updateToken: function (state, payload) {
      const accessToken = payload['jwt-access-token'];
      const refreshToken = payload['jwt-refresh-token'];
      const role_detail = payload['resultData'];

      if (accessToken) {
        state.accessToken = accessToken;
        const decodeAccessToken = jose.decodeJwt(accessToken);

        state.email = decodeAccessToken.userInfo.email;
        state.phone = decodeAccessToken.userInfo.phone;
        state.name = decodeAccessToken.userInfo.name;
        state.branchType = role_detail.branchType;
        state.royalty = decodeAccessToken.userInfo.royalty;
        state.userGroup = decodeAccessToken.userInfo.userGroup;
        state.userIdx = role_detail.userIdx;

        // state.adminId = decodeAccessToken.userInfo.adminId;
        // state.adminDept = decodeAccessToken.userInfo.adminDept;
        // state.adminName = decodeAccessToken.userInfo.adminName;
        // state.adminScrGrpCd = decodeAccessToken.userInfo.adminScrGrpCd;
      }

      if (refreshToken) {
        state.refreshToken = refreshToken;
      }

      if (role_detail && role_detail.length) {
        state.role_detail = role_detail;
      }
    },
    logout: function (state) {
      state.accessToken = '';
      state.refreshToken = '';
      state.email = '';
      state.phone = '';
      state.name = '';
      state.branchType = '';
      state.userIdx = '';
      // state.adminId = '';
      // state.adminDept = '';
      // state.adminName = '';
      // state.adminScrGrpCd = '';
      // state.roleDetail = [];
      state.navigator = [];
    },
  },
  getters: {
    getToken(state) {
      return {
        'jwt-access-token': state.accessToken,
        'jwt-refresh-token': state.refreshToken,
      };
    },
    getNav(state) {
      return state.navigator;
    },

    isLogin(state) {
      return !tokenUtil.expirationCheck({
        'jwt-access-token': state.accessToken,
        'jwt-refresh-token': state.refreshToken,
      });
    },

    getEmail(state) {
      return state.email;
    },
    getPhone(state) {
      return state.phone;
    },
    getName(state) {
      return state.name;
    },
    getBranchType(state) {
      return state.branchType;
    },
    getRoyalty(state) {
      return state.royalty;
    },
    getUserGroup(state) {
      return state.userGroup;
    },
    getUserIdx(state) {
      return state.userIdx;
    },

    // getAdminId(state) {
    //   return state.adminId;
    // },
    // getAdminScrGrpCd(state) {
    //   return state.adminScrGrpCd;
    // },
  },
};

export default userStore;
