import Home from '@/router/views/Home.vue';

export default {
  path: '/cna-news',
  name: 'cnaNews',
  component: Home,

  children: [
    {
      path: 'customer-qna',
      component: () => import('@components/cnaNews/customer_qna/CustomerQna.vue'),
    },
    {
      path: 'customer-qna/write',
      component: () => import('@components/cnaNews/customer_qna/CustomerQnaWrite.vue'),
    },
    {
      path: 'customer-qna/write/:id',
      name: 'customer-qna/write/reply',
      component: () => import('@components/cnaNews/customer_qna/CustomerQnaWrite.vue'),
      props: true,
    },
    {
      path: 'customer-qna/view/:id',
      component: () => import('@components/cnaNews/customer_qna/CustomerQnaView.vue'),
    },

    {
      path: 'notice',
      component: () => import('@components/cnaNews/notice/Notice.vue'),
    },
    {
      path: 'notice/write',
      component: () => import('@components/cnaNews/notice/NoticeWrite.vue'),
    },
    {
      path: 'notice/write/:id',
      name: 'notice/write/reply',
      component: () => import('@components/cnaNews/notice/NoticeWrite.vue'),
      props: true,
    },
    {
      path: 'notice/view/:id',
      component: () => import('@components/cnaNews/notice/NoticeView.vue'),
    },

    {
      path: 'video-room',
      component: () => import('@components/cnaNews/video_room/VideoRoom.vue'),
    },
    {
      path: 'video-room/write',
      component: () => import('@components/cnaNews/video_room/VideoRoomWrite.vue'),
    },
    {
      path: 'video-room/write/:id',
      name: 'video-room/write/reply',
      component: () => import('@components/cnaNews/video_room/VideoRoomWrite.vue'),
      props: true,
    },
    {
      path: 'video-room/view/:id',
      component: () => import('@components/cnaNews/video_room/VideoRoomView.vue'),
    },

    {
      path: 'gallery',
      component: () => import('@components/cnaNews/gallery/Gallery.vue'),
    },
    {
      path: 'gallery/write',
      component: () => import('@components/cnaNews/gallery/GalleryWrite.vue'),
    },
    {
      path: 'gallery/write/:id',
      name: 'gallery/write/reply',
      component: () => import('@components/cnaNews/gallery/GalleryWrite.vue'),
      props: true,
    },
    {
      path: 'gallery/view/:id',
      component: () => import('@components/cnaNews/gallery/GalleryView.vue'),
    },
  ],
};
