import Axios from 'axios';
import router from '@/router/index';
import tokenUtil from '@utils/tokenUtil.js';
import store from '@/store/index';
import qs from 'qs';

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
  function (config) {
    const getToken = tokenUtil.makeTokenHeaders(store.getters['userStore/getToken']);

    config.headers['jwt-access-token'] = getToken['jwt-access-token'];
    config.headers['jwt-refresh-token'] = getToken['jwt-refresh-token'];

    config.paramsSerializer = params => qs.stringify(params);

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    const loginPayload = {};

    loginPayload['jwt-access-token'] = response.headers['jwt-access-token'];
    loginPayload['jwt-refresh-token'] = response.headers['jwt-refresh-token'];
    if (response.config.url === '/login') {
      loginPayload['resultData'] = response.data.resultData;
      store.commit('userStore/updateToken', loginPayload);
    }

    return response;
  },
  async function (error) {
    console.error(error);

    alert(await getErrorMsg(error));

    if (error.response.status === 401) {
      store.commit('userStore/logout');
      await router.push('/login');
    }
    return Promise.reject(error);
  }
);

async function getErrorMsg(error) {
  let responseData = error.response.data;
  if (
    error.response.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') != -1
  ) {
    responseData = JSON.parse(await error.response.data.text());
  }

  let alertMsg;
  if (responseData.errorMessage) {
    alertMsg = responseData.errorMessage;
  } else {
    alertMsg = '작업 처리중 오류가 발생했습니다.';
  }

  if (responseData.showStatus) {
    alertMsg += `\n[ ${error.response.status} Error ]`;
  }

  if (responseData.errorCode) {
    alertMsg += `\n[ 에러코드 : ${responseData.errorCode} ]`;
  }

  return alertMsg;
}

export default axios;
