import { fileWhitelist, imageFileWhitelist, documentFileWhitelist } from '@constants/fileWhitelist';

const constant = {
  namespaced: true,
  state: {
    fileWhitelist,
    imageFileWhitelist,
    documentFileWhitelist,
  },
  getters: {
    fileWhitelist: state => {
      return state.fileWhitelist;
    },
    fileWhitelistExtension: state => {
      return state.fileWhitelist.extension;
    },
    fileWhitelistMediaTypes: state => {
      return state.fileWhitelist.mediaTypes;
    },

    imageFileWhitelist: state => {
      return state.imageFileWhitelist;
    },
    imageFileWhitelistExtension: state => {
      return state.imageFileWhitelist.extension;
    },
    imageFileWhitelistMediaTypes: state => {
      return state.imageFileWhitelist.mediaTypes;
    },
  },
};

export default constant;
